import React, { Component } from "react";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

class Welcome extends Component {

    render() {
        const { t } = this.props;

        //Welcome loop start
        const welcomedata = this.props.welcomesData.map((welcome, index) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                <div className="single-box">
                    <Icofont icon={welcome.icon} />
                    <h3>{t(welcome.title)}</h3>
                    <p>{t(welcome.content)}</p>
                </div>
            </div>
        ));
        //Welcome loop END

        return (
            <React.Fragment>
                <section className="welcome-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <span>{t("We are cloud native")}</span>
                            <h2>{t('Welcome to')} <b>kscloud</b></h2>
                            <p>{t('Initial description')}</p>
                        </div>

                        <div className="row">
                            {welcomedata}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

Welcome.propTypes = {
    welcomesData: PropTypes.array
};

Welcome.defaultProps = {
    welcomesData: [
        {
            icon: "icofont-architecture",
            title: "Building",
            content: "Welcome Building"

        },
        {
            icon: "icofont-check-circled",
            title: "Reviewing",
            content: "Welcome Reviewing",

        },
        {
            icon: "icofont-school-bag",
            title: "Training",
            content: "Welcome Training"
        }
    ]
};

export default withTranslation()(Welcome);
