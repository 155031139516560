import React, { Component } from 'react';

//Components List
import NavBarToHome from "../components/NavBarToHome";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";


class PrivacyPolicy extends Component {
    render() {
        return (
            <React.Fragment>
                <NavBarToHome />
                <React.Fragment>
                    <section className="ptb-100">
                        <div className="container">
                            <h3>Privacy Policy </h3> <br />

                            <p>
                                At kscloud.pl, accessible from <span>https://kscloud.pl</span>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by kscloud.pl and how we use it. If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
                        </p>
                            <br />
                            <h5>Log Files</h5>

                            <p>
                                <span>kscloud.pl</span> follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and timestamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
                        </p>

                            <br />
                            <h5>Privacy Policies</h5>

                            <p>
                                You may consult this list to find the Privacy Policy for each of the advertising partners of kscloud.pl.
                        </p>

                            <p>
                                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on kscloud.pl, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                        </p>

                            <p>
                                Note that kscloud.pl has no access to or control over these cookies that are used by third-party advertisers.
                        </p>

                            <br />
                            <h5>Service Partners</h5>
                            <p>We may use the following third-party service providers named below to process and store your data</p>

                            <ul>
                                <li><span>Formcarry - https://formcarry.com </span> - 2035 Sunset Lake Road, Suite B-2, Newark, Delaware 19702 <p>Third party service used for forwarding messages from our contact form to our contact mailbox (contact@kscloud.pl). Formcarry receives name, email address and message you provide in the contact form.</p></li>
                            </ul>

                            <br />
                            <h5>Third Party Privacy Policies</h5>
                            <p>
                                kscloud.pl's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.
                        </p>

                            <p>
                                You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
                        </p>

                            <br />
                            <h5>Online Privacy Policy Only</h5>

                            <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and / or collect in kscloud.pl. This policy is not applicable to any information collected offline or via channels other than this website.</p>

                            <br />
                            <h5>Consent</h5>

                            <p>
                                By using our website, you hereby consent to our Privacy Policy and agree to its <a href="/terms">Terms and Conditions.</a>

                            </p>
                        </div>
                    </section>
                </React.Fragment >
                <Footer />
                <ScrollUpBtn />
            </React.Fragment>
        );
    }
}
export default PrivacyPolicy;


