import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

export class Partnership extends Component {
    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <section className="partnerships">
                    <div className="container">
                        <h2>{t('We are an AWS Select Partner')}</h2>
                        <img src={this.props.aws_select_badge} />
                    </div>
                </section>
            </React.Fragment>
                );
            }
        }
    
    
Partnership.PropsTypes = {
    btnlink: PropTypes.string
}

Partnership.defaultProps = {
    aws_select_badge: require("../../src/assets/img/aws/select_badge.jpg")
}

export default withTranslation()(Partnership);
