import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

export class Work extends Component {
    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <section className="work-with-us ptb-100">
                    <div className="container">
                        <h3>{t('Want to work with us')}</h3>
                        <span>{t('Lets Talk')}</span>
                        <a href={this.props.btnlink} className="btn btn-primary">{t('Get Started')}</a>
                    </div>
                    {/* <div className="animation-box1"><img src={this.props.animationimg1} alt="animateimage" /></div>
                <div className="animation-box2"><img src={this.props.animationimg2} alt="animateimage" /></div>
                <div className="animation-box3"><img src={this.props.animationimg3} alt="animateimage" /></div> */}
                </section>
            </React.Fragment>
        );
    }
}
Work.PropsTypes = {
    btnlink: PropTypes.string,
    animationimg1: PropTypes.string,
    animationimg2: PropTypes.string,
    animationimg3: PropTypes.string,
}
Work.defaultProps = {
    btnlink: "#contact",
    animationimg1: require("../../src/assets/img/3.png"),
    animationimg2: require("../../src/assets/img/4.png"),
    animationimg3: require("../../src/assets/img/1.png")
}
export default withTranslation()(Work);
