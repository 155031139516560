import React, { Component } from "react";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';

class DataArchivization extends Component {
    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div class="hero">
                    <div class="row hero-text justify-content-center">
                        <div className="col-lg-12 section-title">
                            <h2>{t('Archivization Title')} <b>{t('Archivization Title Bold')}</b></h2>
                        </div>
                        <div className="col-lg-6">
                            <p>{t('Archivization Subtitle')}</p>
                        </div>
                    </div>
                </div>

                <div className="container service-description col-11 col-xl-6">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-3 paragraph-icon">
                            <img src={require("../../assets/img/993762.svg")} />
                        </div>

                        <div className="col-12 col-md-9 txt-align-left">
                            <p>{t('Archivization Paragraph1')}</p>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 col-md-3 paragraph-icon icon-left">
                            <img src={require("../../assets/img/557227.svg")} />
                        </div>
                        <div className="col-12 col-md-9 txt-align-left" >
                            <p>{t('Archivization Paragraph2')}</p>
                        </div>
                        <div className="col-12 col-md-3 paragraph-icon icon-right">
                            <img src={require("../../assets/img/557227.svg")} />
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-12 col-md-3 paragraph-icon">
                            <img src={require("../../assets/img/809518.svg")} />
                        </div>

                        <div className="col-12 col-md-9 txt-align-left">
                            <p>{t('Archivization Paragraph3')}</p>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <p className="last black">{t('Archivization Paragraph4')}</p>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default withTranslation()(DataArchivization);
