import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

export class News extends Component {
    render() {
        const { t } = this.props;

        //Start News Loop
        const newsdata = this.props.newssData.map((news, index) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                <div className="single-blog-post">
                    <div className="post-thumbnail">
                        <a href={news.imglink}><img src={news.image} alt="blog-img" /></a>
                    </div>

                    <div className="post-content">
                        <h3><a href={news.titlelink}>{news.title}</a></h3>
                        <a href={news.btnlink} className="read-more">{t('read more')}</a>
                    </div>
                </div>
            </div>
        ));
        //End News Loop
        return (
            <React.Fragment>
                <section id="blog" className="blog-area">
                    <div className="container">
                        <div className="section-title">
                            <h2>{t('Our Latest')} <b> {t('Posts')}</b></h2>
                            <p>{t('Visit our blog at')} <a href={this.props.linkToBlog}>{this.props.linkToBlog}</a></p>
                        </div>

                        <div className="row">
                            {newsdata}


                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
News.PropsTypes = {
    newssData: PropTypes.array,
    linkToBlog: PropTypes.string

}
News.defaultProps = {
    linkToBlog: "https://medium.com/@kscloud",
    newssData: [
        {
            imglink: "https://medium.com/@kscloud/what-is-a-cloud-landing-zone-64b5ee07faaa",
            image: require("../assets/img/blog/landing.jpg"),
            titlelink: "https://medium.com/@kscloud/what-is-a-cloud-landing-zone-64b5ee07faaa",
            title: "What is a cloud landing zone",
            btnlink: "https://medium.com/@kscloud/what-is-a-cloud-landing-zone-64b5ee07faaa",
        },
        {
            imglink: "https://medium.com/@kscloud/how-to-program-infrastructure-with-pulumi-part-2-3d7d64e69146",
            image: require("../assets/img/pulumilogo_360x270.png"),
            titlelink: "https://medium.com/@kscloud/how-to-program-infrastructure-with-pulumi-part-2-3d7d64e69146",
            title: "How to program infrastructure with Pulumi - part 2",
            btnlink: "https://medium.com/@kscloud/how-to-program-infrastructure-with-pulumi-part-2-3d7d64e69146",
        },
        {
            imglink: "https://medium.com/@kscloud/how-to-program-infrastructure-with-pulumi-part-1-a47d5edb913f",
            image: require("../assets/img/pulumilogo_360x270.png"),
            titlelink: "https://medium.com/@kscloud/how-to-program-infrastructure-with-pulumi-part-1-a47d5edb913f",
            title: "How to program infrastructure with Pulumi - part 1",
            btnlink: "https://medium.com/@kscloud/how-to-program-infrastructure-with-pulumi-part-1-a47d5edb913f",
        },
        {
            imglink: "https://medium.com/@kscloud/restrict-s3-bucket-access-to-cloudflare-ips-only-104583f6fc98",
            image: require("../assets/img/fence_360x270.png"),
            titlelink: "https://medium.com/@kscloud/restrict-s3-bucket-access-to-cloudflare-ips-only-104583f6fc98",
            title: "How to restrict S3 bucket access to cloudflare IPs only",
            btnlink: "https://medium.com/@kscloud/restrict-s3-bucket-access-to-cloudflare-ips-only-104583f6fc98",
        },
        {
            imglink: "https://medium.com/@kscloud/what-is-aws-data-exchange-service-627b1612a722",
            image: require("../assets/img/numbers_360x270.png"),
            titlelink: "https://medium.com/@kscloud/what-is-aws-data-exchange-service-627b1612a722",
            title: "What is AWS Data Exchange service?",
            btnlink: "https://medium.com/@kscloud/what-is-aws-data-exchange-service-627b1612a722",
        },
        {
            imglink: "https://medium.com/@kscloud/dynamic-environments-with-auth0-ed48579be3",
            image: require("../assets/img/Auth0-Logo-Dark-BG.png"),
            titlelink: "https://medium.com/@kscloud/dynamic-environments-with-auth0-ed48579be3",
            title: "Dynamic environments with Auth0.",
            btnlink: "https://medium.com/@kscloud/dynamic-environments-with-auth0-ed48579be3",
        },
        {
            imglink: "https://medium.com/@kscloud/iq-new-aws-service-for-freelancers-22b1ff488abc",
            image: require("../assets/img/aws_iq.jpg"),
            titlelink: "https://medium.com/@kscloud/iq-new-aws-service-for-freelancers-22b1ff488abc",
            title: "IQ — new AWS service for freelancers.",
            btnlink: "https://medium.com/@kscloud/iq-new-aws-service-for-freelancers-22b1ff488abc",
        }
    ]
}

export default withTranslation()(News);
