import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import ContactUs from "../components/ContactUs";

export class Footer extends Component {

  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <footer className="footer-area">
          <ContactUs />
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <img src={this.props.logo} width="80" />
                <p>{t('Footer Copyright')}</p>
              </div>

              <div className="col-lg-6 col-md-6">
                <ul>
                  <li>
                    <a href="/terms">
                      {t('Menu TandC')}
                    </a>
                  </li>
                  <li>
                    <a href="/privacypolicy">
                      {t('Menu PrivacyPolicy')}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-2">
                <a href="https://aws.amazon.com/what-is-cloud-computing"><img src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing" /></a>
              </div>

            </div>
          </div>
        </footer>
      </React.Fragment >
    );
  }
}


Footer.defaultProps = {
  logo: require("../assets/img/kscloud-logo/kscloud.svg"),
};
export default withTranslation()(Footer);
