import React, { Component } from "react";
import { Link } from "react-scroll";
import { Link as RRLink } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import NavBarBrand from "../components/NavBarBrand";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n";
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDropdowns: {
                lang: false
            }
        }
    }

    resetDropdowns() {
        this.setState({
            activeDropdowns: {
                lang: false
            }
        })
    }

    setLang(lang) {
        i18n.changeLanguage(lang);
        this.resetDropdowns();
    }

    toggleDropdown(dropdown) {
        if (this.state.activeDropdowns[dropdown] != true) {
            this.setState({
                activeDropdowns: { [dropdown]: true }
            })
        }
    }

    getLangLiteral(lang) {
        var langs = new Map([
            ["en", "English"], ["pl", "Polski"], ["no", "Norsk"]
        ]);
        return langs.get(lang);
    }

    getLangLinks() {
        const links = new Map([
            ["en", ["pl", "no"]],
            ["pl", ["en", "no"]],
            ["no", ["pl", "en"]],
        ]);

        const lang = i18n.language || "en";

        return (
            <React.Fragment>
                {links.get(lang).map(lang => (
                    <Nav.Item>
                        <RRLink
                            activeClass="active"
                            to={"/" + lang}
                            className="nav-link"
                            onClick={() => this.setLang(lang)}
                        >
                            {this.getLangLiteral(lang)}
                        </RRLink>
                    </Nav.Item>
                ))
                }
            </React.Fragment>
        )
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <Navbar
                    id="navbar"
                    bg="light"
                    expand="lg"
                    className="navbar navbar-expand-lg navbar-light bg-light"
                    collapseOnSelect={true}
                >
                    <Container>
                        <NavBarBrand />
                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            id="collaspe-btn" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="top"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                    >
                                        {t('Menu Home')}
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="about"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                    >
                                        {t('Menu About')}
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="services"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                    >
                                        {t('Menu Services')}
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="blog"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                    >
                                        Blog
                                </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="contact"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                    >
                                        {t('Menu Contact')}
                                    </Link>
                                </Nav.Item>

                                {/* <Dropdown disabled="true" active={this.state.activeDropdowns.lang}>
                                    <DropdownTrigger>
                                        <div className="nav-item">
                                            <a onClick={() => this.toggleDropdown("lang")} className="nav-link">{this.getLangLiteral(i18n.language)}<i class="icofont-caret-down"></i></a>
                                        </div>
                                    </DropdownTrigger>
                                    <DropdownContent>
                                        {this.getLangLinks()}
                                    </DropdownContent>
                                </Dropdown> */}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </React.Fragment >
        );
    }
}

export default withTranslation()(NavBar);
