import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const SupportedLanguages = ["en"]

const resources = {
    en: {
        translation: {
            "We are cloud native": "We are cloud native",
            "Menu Home": "Home",
            "Menu Services": "Services",
            "Menu Contact": "Contact",
            "Menu TandC": "Terms & Conditions",
            "Menu PrivacyPolicy": "Privacy Policy",
            "Menu About": "About Us",
            "Welcome to": "Welcome to",
            "Initial description": "We provide full-scope cloud consulting services for your business. Make a maximum of availability, reliability, security, and cost-efficiency utilizing modern public cloud infrastructure.",
            "Welcome Building": "We help our customers by building for them best quality cloud infrastructure according to their needs. We rely heavily on automation and Infrastructure as Code so our customers can easily extend and modify it.",
            "Welcome Reviewing": "We help our customers by carefully reviewing their infrastructure according to cloud best practises and propose improvements on availability, reliability, security or cost efficiency.",
            "Welcome Training": "We help our customers by making their staff feeling comfortable with cloud technologies. Our professional training services provide cloud and devops trainings delivered at our customers premises.",
            "Services initial description": "We focus solely on the public cloud and provide only services related to it. We embrace the classic UNIX philosophy - Do one thing, and do it well.",
            "Cloud migration content1": "Do you think about moving your application workloads to the cloud? Our team will help you build the business case for migration and will prepare for you a detailed Total Cost of Ownership comparison between cloud and on-premise solution. Once the decision is made we will prepare for you the migration strategy",
            "Cloud migration content2": "present you a detailed migration plan and perform it.",
            "Core infrastructure content": "Core Infrastructure plays a vital role in your company's cloud journey. Including aspects like Identity and Access Management, Log Management, Auditing, or Networking works as a basic building block upon which you deploy your application workloads. Our team will build for you a Core Infra suited to your needs using our cutting-edge automation tools.",
            "Infrastructure review content": "Our professional staff can review your infrastructure and propose improvements in availability, reliability, security, or cost-efficiency.",
            "Application modernisation content": "To get a maximum of the cloud your application has to be Cloud Native. Even though the migration of the applications \"as is\" from on-premise to the cloud will work, you will not be able to take advantage of all the benefits that cloud gives. We will analyze your application, how it is built, and provide you with guidance on how to turn it into a cutting-edge Cloud Native application.",
            "Trainings content": "Do you want to learn whether the cloud is a good choice for your company? Maybe you want to make your staff feel comfortable with basic cloud computing aspects or turn your on-premise Engineers into a professional Cloud Engineers? Use our professional services which will deliver training at your premises.",
            "Well-architected content": "AWS Well-Architected framework is an official blueprint created by AWS Solution Architects which helps cloud architects build robust, reliable, and cost-efficient infrastructure on Amazon Web Services cloud. Our team will review your infrastructure and prepare for you a summary of all the changes that will improve your infrastructure.",
            "Application deployment content": "Do you need to deploy a certain application stack in the cloud? Endpoint Protection solution? Log management or Backup and Disaster Recovery solution? No problem. Our team has great experience in deploying various application workloads in the cloud. Thanks to our existing automation and battle-tested Infrastructure as Code snippets we will do that quickly and will leave you with a solution that is easy to extend or modify!",
            "Lets Talk": "Let's talk about project",
            "ContactUs_Contact": "Contact",
            "ContactUs_Us": "Us",
            "Email": "Email",
            "Phone": "Phone",
            "Lang Email": "contact@kscloud.pl",
            "Lang Phone": "+48 534-824-184",
            "Contact our staff": "Contact our staff by using the form on the right or directly at",
            "Footer Copyright": "Copyright @ 2019 kscloud. All rights reserved",
            "Archivization Title": "Data archiving for",
            "Archivization Subtitle": "Build an effective data archiving and decomissioning system to reduce data storage costs to a bare minimum.",
            "Archivization Title Bold": "your business",
            "Archivization Paragraph1": "Over last decade data became one of the most important, if not the most important, asset that companies own. Data-driven organizations put data analytics in the center of their business decision process.",
            "Archivization Paragraph2": "Being data-driven faces company with many challenges. First of all it has to gather lots of data concerning various aspects of its functioning. Then the data has to be stored in a durable storage. Next an analytics framework has to be built that enables company to quantify the outcomes of undertaken initiatives and use the resulting data to further plan the business strategy.",
            "Archivization Paragraph3": "This entire process involves gathering and storing of huge volumes of data. A lot of this data is needed only for short period of time and can be either deleted or archived afterwards. Without a proper data archiving and decommissioning solution company may end up paying money for storing data it doesn’t need.",
            "Archivization Paragraph4": "At kscloud we help companies to build a proper data archival strategy and implement a system backed by public cloud that ensures the costs of data storage are reduced to minimum.",
            "About us": "About us",
            "About paragraph1": "Are you tired of consultancy agencies providing you just a bunch of individuals? Wouldn't it be better to hire a team?",
            "About paragraph2": "We are a team of experienced Cloud Architects/DevOps Engineers who turns passion and dedication into a secure, reliable, and robust cloud solutions.",
            "About paragraph3": "Our main goal is to help your company walking down the cloud journey path. From building the cloud migration business cases, through the design and implementation, to the production deployment.",
            "Our Mission Paragraph1": "Over many years spent in the industry, we have identified many problems that companies face when using external consultancy. Agencies often provide unrelated consultants who have never been working with each other.",
            "Our Mission Paragraph2": "When building cloud solutions you want to build it in a way that will not require a lot of operational work when it is in production. You put a lot of effort into building a fully automated platform to later embrace the scalability, elasticity, and self-healing of the cloud solutions. Because of that the design and implementation phases are vital and it is difficult to get them right without proper teamwork.",
            "Our Mission Paragraph3": "And this is our mission. We want your company to avoid the need of building an entire cloud team - but we also don't want you to end up with a bunch of individual cloud consultants. And here we are - your Cloud Team as A Service."
        }
    },
    pl: {
        translation: {
            "We are cloud native": "Jesteśmy cloud native",
            "Menu Home": "Strona główna",
            "Menu Services": "Usługi",
            "Menu Contact": "Kontakt",
            "Menu TandC": "Warunki korzystania",
            "Menu PrivacyPolicy": "Polityka prywatności",
            "Menu About": "O nas",
            "Welcome to": "Witaj na stronie firmy",
            "Initial description": "Oferujemy pełen zakres usług consultingowych z zakresu chmur obliczniowych dla Twojej firmy. Uzyskaj maksimum dostępności, niezawodności i efektywności dzięki korzystaniu z nowoczesnej infrastruktury IT opartej o najnowsze technologie.",

            "Welcome Building": "Budujemy najwyzszej jakości rozwiązania cloudowe dostosowane do potrzeb naszych klientów. Dzięki wykorzystaniu automatyzacji i paradygmatu Infrastructure as Code ułatwiamy przyszły rozwój i modyfikację zastosowanych rozwiązań.",
            "Welcome Reviewing": "Analizujemy istniejącą infrastrukturę naszych klientów w celu jej optymalizacji wzgodnie ze standardami typu \"best practice\" chmur obliczeniowych. Proponujemy zmiany mające na celu obniżenie kosztów przy jednoczesnym zwiększeniu dostępności, niezawodności i bezpieczeństwa.",
            "Welcome Training": "Oferujemy szkolenia mające na celu zwiększenie kompetencji kadry pracowniczej z zakresu technologii cloudowych. Nasza oferta szkoleniowa obejmuje zarówno wiedzę o chmurach obliczeniowych, jak i szkolenia DevOps dopasowane do wymagań klienta.",
            "Building": "Budujemy",
            "Reviewing": "Sprawdzamy",
            "Training": "Szkolimy",
            "Our": "Nasze",
            "Services": "Usługi",
            "Services initial description": "Koncentrujemy się wyłącznie na chmurach publicznych i oferujemy związane z nimi usługi. Wyznajemy klasyczną filozofię Unix'a - Do one thing, and do it well.",
            "Cloud consultancy": "Consulting",
            "Cloud consultancy content": "Oferujemy usługi consultingowe związane z platformami Amazon Web Services oraz Google Cloud Platform",
            "Core infrastructure": "Infrastruktura rdzeniowa",
            "Core infrastructure content": "Budujemy infrastrukturę rdzeniową w chmurze gotową na przyjęcie Twoich aplikacji.",
            "Infrastructure review": "Audyt infrastruktury",
            "Infrastructure review content": "Przeprowadzamy audyt istniejącej infrastruktury w chmurze szukając obszarów wymagających poprawy pod kątem dostepności, bezpieczeństwa czy redukcji kosztów.",
            "Trainings": "Szkolenia",
            "Trainings content": "Wyszkolimy Twoich pracowników w zakresie budowy, obsługi czy uzytkowania systemów opartych o chmurę obliczeniową. Ofertujemy szkolenia o chmurach obliczeniowych i DevOps, każde dopasowane do potrzeb klienta.",
            "Contact": "Kontakt",
            "Us": "Nas",
            "Want to work with us": "Jesteś zainteresowany współpracą?",
            "Lets Talk": "Porozmawiajmy o projekcie",
            "Phone": "Telefon",
            "Our Latest": "Nasze ostatnie",
            "Posts": "posty",
            "Visit our blog at": "Odwiedź naszego bloga",
            "read more": "czytaj więcej",
            "ContactUs_Contact": "Skontaktuj się z",
            "ContactUs_Us": "nami",
            "Email": "Email",
            "Phone": "Telefon",
            "Lang Email": "kontakt@kscloud.pl",
            "Lang Phone": "+48 534-824-184",
            "Contact our staff": "Skontaktuj się z nami uzywając formularza po prawej lub wyślij wiadomość bezpośrednio na",
            "Get in touch": "Porozmawiajmy",
            "Send Message": "Wyślij wiadomość",
            "Get Started": "Rozpocznijmy",
            "Name": "Imię",
            "Your message": "Twoja wiadomość",
            "Please enter your name": "Uzupełnij swoje imię",
            "Please enter your email address": "Uzupełnij swój adres email",
            "Please write your message": "Uzupełnij pole z wiadomością",
            "Footer Copyright": "Copyright @ 2019 kscloud. Wszelkie prawa zastrzezone",

            "Archivization Title": "Archiwizacja danych dla",
            "Archivization Subtitle": "Stwórz efektywny system archiwizacji i przechowywania danych w celu minimalizacji kosztów.",
            "Archivization Title Bold": "Twojej firmy",
            "Archivization Paragraph1": "Na przestrzeni ostatniej dekady dane stały się jednym z, o ile nie naważniejszym, aktywem  przedsiębiorstw. Analiza danych stanowi centralne zagadnienie podczas podejmowania decyzji biznesowych przez organizacje sterowane danymi.",
            "Archivization Paragraph2": "Firmy oparte na danych mierzą się z wieloma wyzwaniami. Muszą one przede wszystkim zbierać duże ilości danych związane z różnymi aspektami swojej działalności, a także przechowywać zebrane informacje na trwałych nośnikach. Potrzebny jest również framework analityczny, który pozwoli na zmierzenie rezultatów podjętych inicjatyw i użycie zebranych danych w celu zaplanowania dalszej strategii biznesowej.",
            "Archivization Paragraph3": "Cały ten proces oznacza zbieranie i przechowywanie ogromnej ilości danych. Znaczna ich część jest potrzebna tylko przez króki czas, po upływie którego mogłaby zostać usunięta lub zarchiwizowana. Z tego powodu zdarza się, że przedsiębiorstwa, które nie posiadają odpowiedniego systemu archiwizacji i likwidacji danych, płacą za przechowywanie danych, których nie potrzebują.",
            "Archivization Paragraph4": "W kscloud pomagamy przesiębiorstwom budować odpowiednią strategię archiwizacji danych i implementujemy systemy oparte o chmurę publiczną, w efekcie czego zmniejszają się koszty przechowywania danych."
        }
    },
    no: {
        translation: {
            "We are cloud native": "Vi er cloud native",
            "Menu Home": "Hjemmeside",
            "Menu Services": "Serviser",
            "Menu Contact": "Kontakt",
            "Menu TandC": "Betingelser og vilkår",
            "Menu PrivacyPolicy": "Personvernregler",
            "Menu About": "??",
            "Welcome to": "Velkommen på ",
            "Initial description": "Vi leverer et fullt omfang av skytjenester. Bruk den moderne allmenne nettskyen og få maksimal tilgjengelighet, reliabilitet, sikkerhet og kostnadsbesparelse.",
            "Welcome Building": "Vi hjelper våre kunder gjennom å bygge nettskyen av beste kvalitet i henhold til behov. Vi baserer på automatisering og Infrastructure as Code slik at infrastrukturen lett kan utvikles og modifiseres.",
            "Welcome Reviewing": "Vi hjelper våre kunder gjennom å se gjennom infrastrukturen deres for å finne ut hva som kan endres for å bidra til etablering av nettskyens beste praksis. Vi anbefaler mulige forandringer for å redusere kostnadene og forbedre tilgjengeligheten, reliabiliteten og sikkerheten.",
            "Welcome Training": "Vi hjelper våre kunder gjennom å utdanne de ansatte om skytjenester. Vi tilbyr både en profesjonell opplæring innen nettskyen og DevOps kurser etter kundens behov.",
            "Building": "Bygger",
            "Reviewing": "Reviderer",
            "Training": "Utdanner",
            "Our": "Våre",
            "Services": "serviser",
            "Services initial description": "Vi følger den klassiske unix filosofien - Do one thing, and do it well. Ifølge den er vi fokusert utelukkende på allmenne skyen og vi tilbyr bare tjenester knyttet til den.",
            "Cloud consultancy content": "Vi tilbyr rådgivning og veiledning om Amazon Web Services og Google Cloud Platform.",
            "Core infrastructure content": "I skyen bygger vi en sentral infrastruktur som er ferdig til å kjøre applikasjoner.",
            "Infrastructure review content": "Våre fagmennesker kan se gjennom infrastrukturen din og anbefale forandringer som forbedrer tilgjengeligheten, reliabiliteten, sikkerheten, og reduserer kostnadene.",
            "Trainings content": "Vi kan utdanne de ansatte on hvordan man bygger, bruker og drar nytte av nettskyen. Vi tilbyr kurser innen skytjenester og DevOps etter kundens behov.",

            "Contact": "Kontakt",
            "Us": "oss",
            "Want to work with us": "Vil du jobbe med oss?",
            "Lets Talk": "La oss snakke om prosjektet",
            "Phone": "Telefon",
            "Our Latest": "Nyeste",
            "Posts": "poster",
            "Visit our blog at": "Besøk vår blogg",
            "read more": "læs mer",
            "ContactUs_Contact": "Kontakt ",
            "ContactUs_Us": "oss",
            "Email": "E-post",
            "Phone": "Telefon",
            "Lang Email": "kontakt@kscloud.pl",
            "Lang Phone": "+48 534-824-184",
            "Contact our staff": "Kontakt oss for gjennom formularen eller skriv til oss på ",
            "Get in touch": "La oss snakke",
            "Send Message": "Send oss en melding ",
            "Get Started": "Kom igang",
            "Name": "Navn",
            "Your message": "Meldingen",
            "Please enter your name": "Skriv inn navnet",
            "Please enter your email address": "Skriv inn e-posten",
            "Please write your message": "Skriv inn meldingen",

            "Archivization Title": "Data archiving for",
            "Archivization Subtitle": "Build an effective data archiving and decomissioning system to reduce data storage costs to a bare minimum.",
            "Archivization Title Bold": "your business",
            "Archivization Paragraph1": "Over last decade data became one of the most important, if not the most important, asset that companies own. Data-driven organizations put data analytics in the center of their business decision process.",
            "Archivization Paragraph2": "Being data-driven faces company with many challenges. First of all it has to gather lots of data concerning various aspects of its functioning. Then the data has to be stored in a durable storage. Next an analytics framework has to be built that enables company to quantify the outcomes of undertaken initiatives and use the resulting data to further plan the business strategy.",
            "Archivization Paragraph3": "This entire process involves gathering and storing of huge volumes of data. A lot of this data is needed only for short period of time and can be either deleted or archived afterwards. Without a proper data archiving and decommissioning solution company may end up paying money for storing data it doesn’t need.",
            "Archivization Paragraph4": "At kscloud we help companies to build a proper data archival strategy and implement a system backed by public cloud that ensures the costs of data storage are reduced to minimum."
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",

        keySeparator: false,
        whitelist: SupportedLanguages,
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['path', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'subdomain'],
            checkWhitelist: true
        }
    });

export default i18n;
