import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Page from "react-page-loading";

//Package CSS & JS
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template SCSS & CSS Style
import "./assets/css/style.scss";
import "./assets/css/responsive.scss";

//Component Import
import HomeThree from "./pages/HomeThree";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ServicesArchivization from "./pages/ServicesArchivization";

import i18n, { SupportedLanguages } from './i18n';

class App extends Component {
    render() {
        return (
            <div className="App">
                <div>
                    <Page loader={"bubble-spin"} color={"#3F00FF"} size={9}>
                        <Switch>
                            <Route
                                path="/:lang/terms"
                                component={TermsAndConditions}
                            />
                            <Route
                                path="/:lang/privacypolicy"
                                component={PrivacyPolicy}
                            />
                            <Route
                                path="/:lang/services/archivization"
                                component={ServicesArchivization}
                            />
                            <Route
                                path="/:lang/"
                                render={props =>
                                    SupportedLanguages.includes(props.match.params.lang) ? (
                                        <HomeThree />
                                    ) : (
                                            <Redirect to={`/en/${props.match.params.lang}`} />
                                        )
                                }
                            />
                            <Route
                                path="/"
                                component={HomeThree}
                            />
                        </Switch>
                    </Page>
                </div>
            </div>
        );
    }
}
export default App;
