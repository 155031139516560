import React, { Component } from 'react';

//Components List
import NavBar from "../components/NavBar";
import BannerThree from "../components/banner/BannerThree";
import Subscribe from "../components/Subscribe";
import Welcome from "../components/Welcome";
import About from "../components/About";
import Services from "../components/Services";
import Team from "../components/Team";
import Portfolio from "../components/Portfolio";
import Features from "../components/Features";
import Pricing from "../components/Pricing";
import Feadback from "../components/Feadback";
import News from "../components/News";
import Work from "../components/Work";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";
import Partner from "../components/Partner";
import Partnership from "../components/Partnership";
import { Redirect } from 'react-router-dom'

class HomeThree extends Component {
    render() {
        if (window.location.pathname == "/") {
            return <Redirect to="/en" />
        }

        return (
            <React.Fragment>
                <div id="top"></div>
                {/* NavBar: src/components/NavBar */}
                <NavBar />
                {/* BannerThree: src/components/banner/BannerThree */}
                <BannerThree />
                {/* Subscribe: src/components/Subscribe */}
                {/* <Subscribe /> */}
                {/* Welcome: src/components/Welcome */}
                {/* About: src/components/About */}
                <About />
                {/* <Welcome /> */}
                {/* <Partner /> */}
                <Partnership />
                {/* Services: src/components/Services */}
                <Services />
                {/* Team: src/components/Team */}
                {/* <Team /> */}
                {/* Portfolio: src/components/Portfolio */}
                {/* <Portfolio /> */}
                {/* Features: src/components/Features */}
                {/* <Features /> */}
                {/* Feadback: src/components/Feadback */}
                {/* <Feadback /> */}
                {/* Pricing: src/components/Pricing */}
                {/* <Pricing /> */}
                {/* News: src/components/News */}
                <News />
                {/* Work: src/components/Work */}
                {/* <Work /> */}
                {/* ContactUs: src/components/ContactUs */}
                {/* <ContactUs paddingClass="ptb-100" /> */}
                {/* Footer: src/components/Footer */}
                <Footer />
                {/* ScrollUpBtn: src/components/ScrollUpBtn */}
                <ScrollUpBtn />
            </React.Fragment>
        );
    }
}
export default HomeThree;


