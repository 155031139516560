import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

export class NavBarBrand extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar.Brand>
                    <img src={this.props.logo} width="250" /><br />
                </Navbar.Brand>
            </React.Fragment >
        );
    }
}

NavBarBrand.defaultProps = {
    logo: require("../assets/img/kscloud-logo/kscloud-logo.jpg"),
}

export default NavBarBrand;
