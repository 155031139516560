import React, { Component } from 'react';
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

export class ContactUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            successMsg: ""
        };
    }
    handleForm = e => {
        let that = this;
        axios
            .post("https://formcarry.com/s/zhRzW3ovlT_", this.state, {
                headers: { Accept: "application/json" }
            })
            .then(function (response) {
                document.getElementById("contactForm").reset();
                that.setState({
                    successMsg: "We received your submission"
                });
                document.getElementById("contactForm").reset();
            })
            .catch(function (error) { });
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value });


    render() {
        const { t } = this.props;

        //Start ContactUs Loop
        const contactusdata = this.props.contactussData.map((contactus, index) => (
            <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                <div className="contact-box">
                    <div className="icon">
                        <i className={contactus.boxicon} />
                    </div>
                    <h4>{t(contactus.boxtitle)}</h4>
                    <p><a href={contactus.boxitem1link}>{t(contactus.boxitem1)}</a></p>
                    {/* <p><a href={contactus.boxitem2link}>{contactus.boxitem2}</a></p> */}
                </div>
            </div>
        ));
        //End ContactUs Loop
        return (
            <React.Fragment>
                <section id="contact" className={"contact-area " + this.props.paddingClass}>
                    <div className="container">
                        <div className="row justify-content-center">
                            {/* {contactusdata} */}
                            {/* <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className="get-in-touch">
                                    <h2>{t('Get in touch')}</h2><br />
                                </div>
                            </div> */}
                            {/* <div className="col-lg-6"></div> */}
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="section-title">
                                    <h2 style={{ color: "#fff" }}>{t('ContactUs_Contact')} <b>{t('ContactUs_Us')}</b></h2>
                                </div>
                                <ValidationForm
                                    id="contactForm"
                                    onSubmit={(e, formData, inputs) => {
                                        e.preventDefault();
                                        this.handleForm(formData);
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <TextInput
                                                    name="name"
                                                    id="name"
                                                    required
                                                    successMessage=""
                                                    errorMessage={t('Please enter your name')}
                                                    className="form-control"
                                                    placeholder={t('Name')}
                                                    autoComplete="off"
                                                    onChange={
                                                        this.handleFields
                                                    }
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <TextInput
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    required
                                                    successMessage=""
                                                    errorMessage={t('Please enter your email address')}
                                                    className="form-control"
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                    onChange={
                                                        this.handleFields
                                                    }
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="form-group">
                                                <TextInput
                                                    name="message"
                                                    id="description"
                                                    multiline
                                                    placeholder={t('Your message')}
                                                    className="form-control"
                                                    required
                                                    successMessage=""
                                                    errorMessage={t('Please write your message')}
                                                    rows="5"
                                                    autoComplete="off"
                                                    onChange={
                                                        this.handleFields
                                                    }
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 col-sm-12 send-button">
                                            <button type="submit" className="btn btn-primary">{t('Send Message')}</button>
                                            <div id="msgSubmit" className="h3 text-center hidden"></div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </ValidationForm>
                                {this.state.successMsg !== "" ? (
                                    <h3 className="contactMsg">
                                        {this.state.successMsg}
                                    </h3>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}
ContactUs.PropsTypes = {
    fblink: PropTypes.string,
    twitterlink: PropTypes.string,
    instagramlink: PropTypes.string,
    linkedinlink: PropTypes.string,
    contactussData: PropTypes.array,
}
ContactUs.defaultProps = {
    fblink: "#",
    twitterlink: "#",
    instagramlink: "#",
    linkedinlink: "#",
    contactussData: [
        {
            boxicon: "icofont-mail",
            boxtitle: "Email",
            boxitem1link: "#",
            boxitem1: "Lang Email"

        },
        {
            boxicon: "icofont-phone",
            boxtitle: "Phone",
            boxitem1link: "#",
            boxitem1: "Lang Phone"

        }
    ]
}
export default withTranslation()(ContactUs);
