import React, { Component } from 'react';
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import { withTranslation, Trans } from 'react-i18next';


export class Services extends Component {
    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <section id="services" className="services-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <h2>{t('Our')} <b>{t('Services')}</b></h2>
                            <p>{t('Services initial description')}</p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="single-services">
                                    <Icofont icon="" /><br /><br />
                                    <h3>{t('Cloud Migration')}</h3>
                                    <p>{t('Cloud migration content1')}, <a href="https://medium.com/@kscloud/what-is-a-cloud-landing-zone-64b5ee07faaa">landing zone</a>, {t('Cloud migration content2')}</p>
                                    <div className="bg-number">1</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="single-services">
                                    <Icofont icon="" /><br /><br />
                                    <h3>{t('Core Infrastructure')}</h3>
                                    <p>{t('Core infrastructure content')}</p>
                                    <div className="bg-number">2</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="single-services">
                                    <Icofont icon="" /><br /><br />
                                    <h3>{t('AWS Well-architected review')}</h3>
                                    <p>{t('Well-architected content')}</p>
                                    <div className="bg-number">3</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="single-services">
                                    <Icofont icon="" /><br /><br />
                                    <h3>{t('Application modernisation')}</h3>
                                    <p>{t('Application modernisation content')}</p>
                                    <div className="bg-number">4</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="single-services">
                                    <Icofont icon="" /><br /><br />
                                    <h3>{t('Application deployment')}</h3>
                                    <p>{t('Application deployment content')}</p>
                                    <div className="bg-number">5</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="single-services">
                                    <Icofont icon="" /><br /><br />
                                    <h3>{t('Cloud Training')}</h3>
                                    <p>{t('Trainings content')}</p>
                                    <div className="bg-number">6</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="animation-box5">
                        <img src={this.props.animationImg} className="rotateme" alt="vector" />
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
Services.propsTypes = {
    animationImg: PropTypes.string,
    servicesData: PropTypes.array
}
Services.defaultProps = {
    animationImg: require("../../src/assets/img/vector-bg.png"),

    servicesData: [
        {
            icon: "",
            title: "Cloud migration",
            content: "Cloud migration content",
            bgnumber: "1"
        },
        {
            icon: "",
            title: "Core infrastructure",
            content: "Core infrastructure content",
            bgnumber: "2"
        },
        {
            icon: "",
            title: "AWS Well-architected review",
            content: "Well-architected content",
            bgnumber: "3"
        },
        {
            icon: "",
            title: "Application modernisation",
            content: "Application modernisation content",
            bgnumber: "4"
        },
        {
            icon: "",
            title: "Application deployment",
            content: "Application deployment content",
            bgnumber: "5"
        },
        {
            icon: "",
            title: "Cloud Training",
            content: "Trainings content",
            bgnumber: "6"
        }
    ]
}
export default withTranslation()(Services);

