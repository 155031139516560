import React, { Component } from 'react';

//Components List
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";
import DataArchivization from "../components/services/DataArchivization";
import ContactUs from "../components/ContactUs";
import BannerThree from "../components/banner/BannerThree";



class ServicesArchivization extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="top"></div>
                <NavBar />
                <DataArchivization />
                <ContactUs paddingClass="ptb-100" />
                <Footer />
                <ScrollUpBtn />
            </React.Fragment>
        );
    }
}
export default ServicesArchivization;


